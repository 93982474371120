import "./config/load.js"
import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { Skeletor } from "vue-skeletor";
import VueGtag from "vue-gtag-next";
import "bootstrap";

import App from "./App.vue";
import router from "./router";
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./config/msalConfig";
// import { VueReCaptcha } from "vue-recaptcha-v3";

// App variables
const app = createApp(App);
const head = createHead();

// Use plugins globally
app.use(router);
app.use(head);
app.use(VueQueryPlugin);
app.use(msalPlugin, msalInstance);

// app.use(VueReCaptcha, {
//   siteKey: "6Lf1oqgdAAAAAOhP6BOVg12e0RSA06ZKwiaYZVke",
//   loaderOptions: {
//     autoHideBadge: false,
//   },
// });

if (process.env.NODE_ENV === "production") {
  app.use(VueGtag, {
    property: {
      id: "UA-512243-12",
      anonymize_ip: true,
    },
  });
}

// Register components globally
app.component(Skeletor.name, Skeletor);

// Mount app
app.mount("#app");
