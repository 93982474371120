import { LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {
  AUTHENTICATION_CLIENT_ID,
  AUTHORITY_DOMAIN,
  VUE_APP_REDIRECT_URI,
  VUE_APP_POST_LOGOUT_REDIRECT_URI,
} from "./index.js";

const MSAL_CLIENT_ID = AUTHENTICATION_CLIENT_ID();
const MSAL_AUTHORITY = AUTHORITY_DOMAIN();
const MSAL_REDIRECT_URI = VUE_APP_REDIRECT_URI();
const MSAL_POST_LOGOUT_REDIRECT_URI = VUE_APP_POST_LOGOUT_REDIRECT_URI();

export const tokenRequest = {
  // clientId: '2bab9eff-f731-4bc9-8560-d53b78aa5584',
  scopes: ["api://gbin.info/administrator", "openid", "profile"],
};

export const msalConfig = {
  auth: {
    clientId: MSAL_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority: MSAL_AUTHORITY, // Defaults to "https://login.microsoftonline.com/common"
    redirectUri: MSAL_REDIRECT_URI, // Points to window.location.origin. You must register this URI on Azure portal/App Registration.
    postLogoutRedirectUri: MSAL_POST_LOGOUT_REDIRECT_URI, //environment.postLogoutRedirectUri, // Indicates the page to navigate after logout.
    // navigateToLoginRequestUrl: MSAL_NAVIGATE_TO_LOGIN_REQUEST_URL, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      // loggerCallback(logLevel, message) {
      //     console.log(logLevel,message);
      // },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
    allowNativeBroker: false, // Disables WAM Broker //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6169
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
