<script setup>
import Footer from "@/components/Footer.vue";

import Header from "@/components/Header.vue";
import Spacer from "@/components/Spacer.vue";
import LatestRegistered from "@/components/LatestRegistered.vue";
import BrandIndex from "@/components/BrandIndex.vue";

import { computed, onMounted, reactive, ref } from "vue";
import { useHead } from "@vueuse/head";
import { api } from "@/utils/api";
import { VUE_APP_LINK_SYNCFORCE } from "../config/index.js";

/**
 *  GETTING & LOADING (API) DATA
 */

// App variables
const syncforceLink = VUE_APP_LINK_SYNCFORCE();
const loading = ref(false);

// API results variables
const totalCount = ref(0);
const brands = ref([]);

// SEO
const siteData = reactive({
  title: "GBIN | Global Brand Identification Number",
  description:
    "Global Brand Identification Number (GBIN) is an independent identifier for brands. GBIN Brand identifiers are used to look up brand related information in a database or to link different brand systems and portals. GBIN also supports brand hierarchy information.",
});

useHead({
  title: computed(() => siteData.title),
  meta: [
    {
      name: `description`,
      content: computed(() => siteData.description),
    },
  ],
});

// MOUNTED
onMounted(async () => {
  getMostRecentBrands(12, true);
});

// Load API Most Recent Brands data
async function getMostRecentBrands(count, onlyWithLogo) {
  loading.value = true;

  try {
    const response = await api.getMostRecentBrands(count, onlyWithLogo);
    totalCount.value = response.data.totalCount;
    brands.value = response.data.brands;
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <Header :total-count="totalCount"></Header>

  <!-- main -->
  <main class="home-page flex-grow">
    <!-- content -->
    <div class="content py-3 py-sm-5 bg-primary-faded">
      <div class="container px-4 px-sm-3">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
          <div class="col p-3 p-lg-5">
            <h2 class="h5">
              <i class="fas fa-fingerprint me-2"></i>What is a GBIN?
            </h2>

            <p>
              The Global Brand Identification Number (GBIN) is an identifier for
              brands, developed by SyncForce. GBINs are 10 digits long. The GBIN
              provides a unique identifier preventing brand naming conflicts,
              inconsistent data storage, reporting & dashboards.

              <br />

              <router-link to="/about">More about GBIN<i class="fal fa-arrow-right ms-1"></i></router-link>
            </p>
          </div>

          <div class="col p-3 p-lg-5">
            <h2 class="h5"><i class="fas fa-robot me-2"></i>Why GBINs?</h2>

            <p>
              Digitalization of both commerce and supply is resulting in more
              and more machine-to-machine communication. It is essential that
              machines can identify information uniquely. GBINs enable
              brand-related data exchange between machines, especially across
              organizational boundaries.
            </p>
          </div>

          <div class="col col-sm-12 p-3 p-lg-5">
            <h2 class="h5"><i class="fas fa-plus me-2"></i>About SyncForce</h2>

            <p>
              SyncForce accelerates product success by addressing the entire
              product life cycle, from a predictable product pipeline to
              best-in-class digital availability at every point of choice,
              internal and external.

              <br v-if="syncforceLink" />

              <a v-if="syncforceLink"
                 :href="syncforceLink"
                 target="_blank">Learn more about SyncForce<i class="fal fa-arrow-right ms-1"></i></a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- /content -->

    <Spacer :total-count="totalCount"></Spacer>

    <Latest-Registered :loading="loading"
                       :brands="brands"></Latest-Registered>

    <Brand-Index></Brand-Index>
  </main>
  <Footer></Footer>
  <!-- main -->
</template>

<style lang="scss" scoped>
/*  ==========================================================================
    Custom
    ========================================================================== */
@import "@/assets/scss/_variables";

/*  ==========================================================================
    Home
    ========================================================================== */
h2 {
  color: $gbin-primary;
}
</style>
