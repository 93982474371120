import axios from "axios";
import { VUE_APP_API_HOST, VUE_APP_API_PROTOCOL } from "../config/index.js";
const hostname = VUE_APP_API_HOST();
const protocol = VUE_APP_API_PROTOCOL();

export const api = {
  getTotalCount() {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/brands/count`,
    });
  },

  getSearchResult(searchQuery, limit, offset) {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/brands?search=${searchQuery}&limit=${limit}&offset=${offset}`,
    });
  },

  getMostRecentBrands(count, onlyWithLogo = false) {
    const url = `${protocol}${hostname}/brands/mostrecent?count=${count}${
      onlyWithLogo ? `&onlywithlogo=${onlyWithLogo}` : ""
    }`;

    return axios({ method: "get", url });
  },

  getFilteredBrands(filter) {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/brands?filter=${filter}`,
    });
  },

  getBrandDetails(gbin) {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/brands/${gbin}`,
    });
  },

  getBrandIndex() {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/brands/filters`,
    });
  },

  getIndustries() {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/industries`,
    });
  },

  getCountries() {
    return axios({
      method: "get",
      url: `${protocol}${hostname}/countries`,
    });
  },

  saveForm(type, data) {
    return axios({
      method: "post",
      url: `${protocol}${hostname}/${type}`,
      data,
    });
  },
};
