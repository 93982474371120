<script setup>
import {
  VUE_APP_LINK_SYNCFORCE,
  VUE_APP_LINK_PRIVACY,
  VUE_APP_LINK_LINKEDIN,
} from "../config/index.js";

const syncforceLink = VUE_APP_LINK_SYNCFORCE();
const privacyLink = VUE_APP_LINK_PRIVACY();
const linkedinLink = VUE_APP_LINK_LINKEDIN();
</script>

<template>
  <!-- footer -->
  <footer class="footer flex-shrink-1 mt-auto py-4 bg-gray-dark">
    <div class="container px-4 px-sm-3">
      <div class="row">
        <div class="col-12 col-sm-auto text-center">
          <ul class="list-unstyled d-sm-flex justify-content-sm-start mb-0">
            <li><router-link to="/contact">Contact</router-link></li>

            <li v-if="privacyLink">
              <a :href="privacyLink"
                 target="_blank">Privacy Policy</a>
            </li>

            <!-- <li>
              <router-link to="/terms-and-conditions"
                >Term & Conditions</router-link
              >
            </li> -->
          </ul>
        </div>

        <div v-if="syncforceLink"
             class="col-12 col-sm text-center text-sm-end">
          Powered by
          <a v-if="syncforceLink"
             :href="syncforceLink"
             title="Product Success Platform - SaaS - SyncForce"
             target="_blank">SyncForce</a>

          <a v-if="linkedinLink"
             :href="linkedinLink"
             class="ms-1"
             title="SyncForce | LinkedIn"
             target="_blank"><i class="fab fa-linkedin"></i></a>
        </div>
      </div>
    </div>
  </footer>
  <!-- /footer -->
</template>

<style lang="scss" scoped>
/*  ==========================================================================
    Bootstrap
    ========================================================================== */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/*  ==========================================================================
    Footer
    ========================================================================== */
.footer {
  color: $gray-600;

  a {
    white-space: nowrap;
    color: $black;

    &:hover {
      color: $link-hover-color;
    }
  }

  ul {
    li {
      &+li::before {
        @include media-breakpoint-up(sm) {
          display: inline-block;
          padding: 0 2.5px;
          content: " | ";

          text-align: center;
          color: $gray-400;
        }
      }
    }
  }
}
</style>
