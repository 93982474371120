

let loadConfig = {};
export const load = async () => {
    const baseUrl = new URL(`https://${window.location.hostname}/config.json`)
    const xmlhttp = new XMLHttpRequest();
    try {
        xmlhttp.open("GET", baseUrl, false);
        xmlhttp.send();
            if (xmlhttp.status === 200) {
                loadConfig = JSON.parse(xmlhttp.responseText);
            }
        } catch (error) {
            console.error("Failed to load config");
        }
}

export const get = (key) => loadConfig[key];
export const VUE_APP_API_HOST = () => loadConfig.VUE_APP_API_HOST || "localhost:44368";
export const VUE_APP_API_PROTOCOL = () => loadConfig.VUE_APP_API_PROTOCOL || "https://";
export const VUE_APP_LINK_LINKEDIN = () => loadConfig.VUE_APP_LINK_LINKEDIN || "https://www.linkedin.com/company/syncforce";
export const VUE_APP_LINK_PRIVACY = () => loadConfig.VUE_APP_LINK_PRIVACY || "https://www.syncforce.com/company/privacy-policy";
export const VUE_APP_LINK_SYNCFORCE = () => loadConfig.VUE_APP_LINK_SYNCFORCE || "https://www.syncforce.com";
export const AUTHORITY_DOMAIN = () => loadConfig.AUTHORITY_DOMAIN || "https://login.microsoftonline.com/10848d45-c9bd-4ea4-9573-1e6c13b8c23c";
export const AUTHENTICATION_CLIENT_ID = () => loadConfig.AUTHENTICATION_CLIENT_ID || "2bab9eff-f731-4bc9-8560-d53b78aa5584";
export const VUE_APP_REDIRECT_URI = () => loadConfig.REDIRECT_URI || "/";
export const VUE_APP_POST_LOGOUT_REDIRECT_URI = () => loadConfig.POST_LOGOUT_REDIRECT_URI || "/";

export default {
    get,
    VUE_APP_API_HOST,
    VUE_APP_API_PROTOCOL,
    AUTHORITY_DOMAIN,
    AUTHENTICATION_CLIENT_ID,
    VUE_APP_REDIRECT_URI,
    VUE_APP_POST_LOGOUT_REDIRECT_URI,
    VUE_APP_LINK_LINKEDIN,
    VUE_APP_LINK_PRIVACY,
    VUE_APP_LINK_SYNCFORCE
}